import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { isParamPresentInURL } from "../utils/universalHelperFunctions";
import ErrorBoundary from "../components/ErrorBoundary";
import { Header, SliceZone, Footer } from "components";
import "../styles/index.css";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.homepagEventsData = [];
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    var url = window.location.href || '';
    if (isParamPresentInURL('id', url)) {
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      sessionStorage.setItem('email', value);
    }
    if (sessionStorage.getItem('email')) {
      let emailID = sessionStorage.getItem('email');
      let requestBody = `actid=${process.env.ACTIVE_CAMPAIGN_ID}&key=${process.env.ACTIVE_CAMPAIGN_KEY}&event=site_visited&eventdata=${window.location.pathname}&visit=%7B%22email%22%3A%22${emailID}%22%7D`;
      fetch(process.env.ACTIVE_CAMPAIGN_EVENT_REG_URL, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: requestBody
      })
        .then(resp => {
          console.log(resp);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getFeaturedResourceDataHomePage(resourceId) {
    this.allPrismicResource.edges.map((item, index) => {
    });
  }

  render() {
    var isWindow = typeof window !== 'undefined';
    let homepageFeaturedResourceData = [];
    let homepageEventsData = [];

    const {
      data: {
        homepage,
        heroMenu,
        serviceContentCardData,
        footerData,
        allPrismicPage,
        allPrismicResource,
        allPrismicJobDescriptionPage,
        headerMenu,
        prismicCareerFooter
      }
    } = this.props;
    console.log('homepage: ', homepage);

    let metaDataSlice = homepage.data.body.findIndex(function (sl) {
      return sl.slice_type === "metadata_section";
    });
    let overviewSlice = homepage.data.body.findIndex(function (sl) {
      return sl.slice_type === "page_overview";
    });
    let metaDataContent = homepage.data.body[metaDataSlice].primary;
    let pageTitle = homepage.data.body[overviewSlice].primary.page_title.text;
    let pageURL = this.props.location.href;

    //this flag is marked as a false if we are on an other page than resource landing page
    //We are maintaining the filter only for resource pages and internal navigations and not throughout the website
    if (typeof localStorage !== 'undefined') {
      let resourceFlag = localStorage.getItem('resourceFlag');
      if (resourceFlag && resourceFlag === 'true') {
        localStorage.setItem('resourceFlag', 'false');
      }
      let breadCrumbFlag = localStorage.getItem('breadCrumbFlag');
      if (breadCrumbFlag && breadCrumbFlag === 'true') {
        localStorage.setItem('breadCrumbFlag', 'false');
      }
      let searchWordFlag = localStorage.getItem('searchWordFlag');
      if (searchWordFlag && searchWordFlag === 'true') {
        localStorage.setItem('searchWordFlag', 'false');
      }
      let resourceTagFlag = localStorage.getItem('resourceTagFlag');
      if (resourceTagFlag && resourceTagFlag === 'true') {
        localStorage.setItem('resourceTagFlag', 'false');
      }
      let jobFilterFlag = localStorage.getItem('jobFilterFlag');
      if (jobFilterFlag && jobFilterFlag === 'true') {
        localStorage.setItem('jobFilterFlag', 'false');
      }
    }

    return (
      <ErrorBoundary>
        <div className="col-12 p-0 index-page">
          <Helmet>
            {/* General tags */}
            <title>{pageTitle}</title>
            <meta property="og:title" content="GSPANN | Consulting Services, Technology Services, and IT Services Provider" />
            <meta property="og:url" content={pageURL} />
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="en_US" />
            <meta name="robots" content="noodp" />
            <meta property="og:description" content={metaDataContent.metadata_description.text} />
            {/* <meta property="og:image" content={heroContent.Desktop.localFile.childImageSharp.fluid.originalImg}/> */}
            <meta name="twitter:card" content="summary_large_image" />
            {/* <meta name="twitter:image" content={heroContent.Desktop.localFile.childImageSharp.fluid.originalImg}/>        */}

            <meta
              name="description"
              content={metaDataContent.metadata_description.text}
            />
            <meta
              name="keywords"
              content={metaDataContent.metadata_keywords.text}
            />
            <meta name="robots" content={metaDataContent.metadata_robots} />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />
            <meta name="google-site-verification" content="DfDqsqVWWvRxc2RogNvh-o-MRfddK_RHwYlqBmgVQ7w" />
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="canonical" href={pageURL} hrefLang="en" />
            <link rel="alternate" href={pageURL} hrefLang="en" key="en" />
          </Helmet>
          <Header
            logoUrl={headerMenu.data.logo_url}
            menuData={headerMenu.data.menu_links}
            pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)}
          />
          <main role="main" id="main-searchcontainer"></main>
          {
            isWindow && (
              <Suspense fallback={<div style={{ height: window.innerHeight }}></div>}>
                <SliceZone
                  allSlices={homepage.data.body}
                  homePageFeaturedResourceData={homepageFeaturedResourceData}
                  homepageEventsData={homepageEventsData}
                  resourceTilesData={allPrismicResource.edges}
                  pagesLinksData={allPrismicPage.edges}
                  pageId={homepage.uid}
                  jobListData={allPrismicJobDescriptionPage.edges}
                />
                <Footer
                  footerData={prismicCareerFooter.data}
                  pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)}
                />
              </Suspense>
            )
          }
        </div>
      </ErrorBoundary>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.object
};

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicCareerHomepage {
      id
      uid
      type
      data {
        body {
        __typename
          ... on PrismicCareerHomepageBodyCareerBanner {
              id
              slice_type
              primary {
                primary_heading {
                  text
                }
                secondary_heading {
                  text
                }
                mobile_view_heading_1 {
                  text
                }
                mobile_view_heading_2 {
                  text
                }
                mobile_view_heading_3 {
                  text
                }
                banner_image {
                  url
                  Tablet {
                    localFile {
                      childImageSharp {
                        fluid (quality: 90) {
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                        }
                      }
                    }
                  }
                  MobileHDPI {
                    localFile {
                      childImageSharp {
                        fluid (quality: 90) {
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                        }
                      }
                    }
                  }
                  Desktop {
                    localFile {
                      childImageSharp {
                        fluid (quality: 90) {
                          aspectRatio
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          sizes
                          originalImg
                          originalName
                        }
                      }
                    }
                  }
                }
                banner_description {
                  text
                }
              }
              items {
                button_one {
                  text
                }
                button_two {
                  text
                }
                button_one_url {
                  url
                }
                button_two_url {
                  url
                }
              }
          }
          ... on PrismicCareerHomepageBodyCareerdescription {
            id
            slice_type
            primary {
              title {
                text
              }
              background_colour
              description_link {
                url
                id
              }
              description {
                text
              }
              image_position 
              image {
                url
              }
              image_columns
            }
          }
          ... on PrismicCareerHomepageBodyListWithIcons {
            id
            slice_type
            primary {
              heading {
                text
              }
              background_color
            }
            items {
              icon {
                url
              }
              list_item {
                text
              }
              list_icon_code {
               text
              }
              list_item_mobile {
                text
              }
              list_icon_code_mobile {
                text
              }
            }
          }
          ... on PrismicCareerHomepageBodyBannerNumbers {
            id
            slice_type
            primary {
              number_background_color
              number_background_image {
                url
              }
            }
            items {
              number_key {
                text
              }
              number_value {
                text
              }
            }
          }
          
          ... on PrismicCareerHomepageBodyDescriptionWithProfiles {
            id
            slice_type
            primary {
              title {
                text
              }
              background_colour
            }
            items {
              name {
                text
              }
              profile_image { 
                url
              }
              profile_description_popup_image {
                url
              }
              profile_description_popup_image_1 {
              url
              }
              profile_description_popup_image_2 {
              url
              }
              profile_description_popup_image_3 {
              url
              }
              profile_description_popup_image_4 {
              url
              }
              designation {
                text
              }
            }
          }
          
          ... on PrismicCareerHomepageBodyFormDisplaySection {
            id
            slice_type
            primary {
              submit_button_text {
                text
              }
              walkin_role_header {
                text
              }
              walkin_role_sub_header {
              text
              }
              form_call_page
              node_api_url {
                text
              }
              success_message {
                text
              }
              failure_message {
                text
              }
              event {
                text
              }
              event_data {
                text
              }
              form_identifier
            }

            items {
              field_label {
                text
              }
              field_type {
                text
              }
              field_required
              field_label_2 {
                text
              }
              field_type_2 {
                text
              }
              field_required_2
            }
          }

          ... on PrismicCareerHomepageBodySPattern {
            id
            slice_type
            items {
              s_pattern_image {
                url
                alt
                Desktop {
                  alt
                  url
                }
              }
              s_pattern_background_color
              s_pattern_image_position
              s_pattern_text_position
              s_pattern_heading {
                text
              }
              s_pattern_subheading {
                text
              }
            }
          }

          
          ... on PrismicCareerHomepageBodyEmailTemplateLinks {
            id
            slice_type
            __typename
            primary {
              form_identifier_for_teamplate
            }
            items {
              user_type
              email_title {
                text
              }
              email_template_link {
                url
                document {
                  data {
                    body {
                      __typename
                      ... on PrismicEmailTemplateBodyDefaultKeyValue {
                        id
                        slice_type
                        items {
                          key {
                            text
                          }
                          default_value {
                            text
                          }
                          multiline_default_value {
                            html
                            raw {
                              type
                              text
                            }
                          }
                        }
                      }
                      ... on PrismicEmailTemplateBodyRecipientList {
                        id
                        items {
                          team_name {
                            text
                          }
                          recipients {
                            text
                          }
                        }
                        slice_type
                      }
                    }
                    recipient {
                      text
                    }
                    cc_recipient {
                      text
                    }
                    bcc_recipient {
                      text
                    }
                    from_recipient {
                      text
                    }
                    subject {
                      text
                    }
                    salutation {
                      html
                      raw {
                        type
                        text
                      }
                    }
                    email_content{
                      html
                      raw {
                        type
                        text
                      }
                    }
                    signature {
                      html
                      raw {
                        type
                        text
                      }
                    }
                  }
                }
              }
            }
          }

          ... on PrismicCareerHomepageBodyJobsCards {
            id
            slice_type
            primary {
              title {
                text
              }
              background_colour
            }
            items {
              background_image {
                url
              }
              heading {
                text
              }
              page_url {
                url
                id
              }
            }
          }
          ... on PrismicCareerHomepageBodyMetadataSection {
            id
            slice_type
            primary {
              metadata_description {
                text
              }
              metadata_keywords {
                text
              }
              metadata_robots
            }
          }
            ... on PrismicCareerHomepageBodyPageOverview {
            id
            slice_type
            primary {
              page_heading {
                text
              }
              page_title {
                text
              }
            }
          }
        }
        page_display_name {
          text
          html
        }
      }
    }
    allPrismicPage {
      edges {
        node {
          id
          uid
          data {
            page_display_name {
              text
            }
            page_path {
              text
            }
          }
        }
      }
    }
    allPrismicResource {
      edges {
        node {
          uid
          data {
            resource_display_name {
              text
            }
            page_path {
              text
            }
            body {
              __typename
              ... on PrismicResourceBodyPageOverview {
                primary {
                  page_thumbnail {
                    url
                    alt
                  }
                }
              }
              ... on PrismicResourceBodyResourceOverview {
                primary {
                  resource_type
                  title {
                    text
                  }
                }
              }
              ... on PrismicResourceBodyResourceTags {
                id
                prismicId
                slice_type
                primary {
                  tag_background_color
                  tag_font_color
                  tag_container_background_color
                  redirection_link {
                    url
                    uid
                    target
                    id
                  }
                  resource_card_tag_color
                }
                items {
                  tag_keywords_list
                }
              }
            }
          }
        }
      }
    }
    allPrismicJobDescriptionPage {
      edges {
        node {
          uid
          data {
            job_id {
              text
            }
            page_path {
              text
            }
            job_visible_on_homepage
            page_display_name {
              text
            }
            include_job_in_list
            body {
              __typename
              ... on PrismicJobDescriptionPageBodyJobDescription {
                id
                primary {
                  job_title {
                    text
                    html
                  }
                }
              }
              ... on PrismicJobDescriptionPageBodyKeyDetails {
                id
                primary {
                  job_location {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    heroMenu: prismicHeromenu {
      id
      data {
        display_name {
          html
        }
        nav {
          primary {
            label {
              html
              text
            }
            link {
              url
            }
            label_font_color
            label_font_color_on_hover
            nav_item_background_color
            sub_link_separator_line_color
            sub_link_sub_text_font_color
            sub_link_type_font_color
            sub_nav_link_label_font_color
            nav_column_count
            nav_item_position
          }
          items {
            sub_nav_column_position
            sub_nav_link_lable {
              text
            }
            sub_nav_link {
              url
            }
            sub_link_sub_text {
              text
            }
            sub_link_type {
              text
            }
          }
        }
      }
    }
    headerMenu: prismicCareerMenu {
      id
      data {
        logo_url {
          url
        }
        menu_links {
          link_label {
            text
          }
          link {
            document {
              id
              uid
              href
              data {
                page_path {
                  text
                }
                page_display_name {
                  text
                }
              }
            }
            url
          }
        }
      }
    }
    footerData: prismicFooter {
      data {
        footer_background_color
        footer_font_color
        address {
          text
        }
        copyright_text {
          text
        }
        footer_logo {
          url
        }
        body {
          __typename
          ... on PrismicFooterBodySocialConnection {
            id
            slice_type
            primary {
              social_connection_type {
                text
              }
              social_connection_font_color
            }
            items {
              social_icon
              social_icon_font_color
              social_icon_link {
                url
              }
            }
          }
          ... on PrismicFooterBodyGspannPolicy {
            id
            slice_type
            items {
              policy_name {
                text
              }
              policy_link {
                url
              }
              policy_font_color
            }
          }
          ... on PrismicFooterBodyFooterMenu {
            id
            slice_type
            primary {
              footer_menu_type {
                text
              }
              footer_menu_link {
                url
              }
              menu_type_font_color
            }
            items {
              footer_menu_text {
                text
              }
              footer_menu_link {
                url
              }
              footer_menu_font_color
            }
          }
        }
      }
    }
    prismicCareerFooter {
      data {
        footer_background_color
        footer_font_color
        address {
          text
        }
        copyright_text {
          text
        }
        footer_logo {
          url
        }
        footer_description {
          text
        }
        failure_message {
          text
        }
        success_message {
          text
        }
        event {
          text
        }
        footer_description {
          text
        }
        node_api_url {
          text
        }
        form_call_page
        body {
          __typename
          ... on PrismicCareerFooterBodySocialConnection {
            id
            slice_type
            primary {
              social_connection_type {
                text
              }
              social_connection_font_color
            }
            items {
              social_icon
              social_icon_font_color
              social_icon_link {
                url
              }
            }
          }
          
          ... on PrismicCareerFooterBodyEmailTemplateLinks {
            id
            slice_type
            primary {
              form_identifier_for_teamplate
            }
            items {
              user_type
              email_title {
                text
              }
              email_template_link {
                url
                document {
                  data {
                    body {
                      __typename
                      ... on PrismicEmailTemplateBodyDefaultKeyValue {
                        id
                        slice_type
                        items {
                          key {
                            text
                          }
                          default_value {
                            text
                          }
                          multiline_default_value {
                            html
                            raw {
                              type
                              text
                            }
                          }
                        }
                      }
                      ... on PrismicEmailTemplateBodyRecipientList {
                        id
                        items {
                          team_name {
                            text
                          }
                          recipients {
                            text
                          }
                        }
                        slice_type
                      }
                    }
                    recipient {
                      text
                    }
                    cc_recipient {
                      text
                    }
                    bcc_recipient {
                      text
                    }
                    from_recipient {
                      text
                    }
                    subject {
                      text
                    }
                    salutation {
                      html
                      raw {
                        type
                        text
                      }
                    }
                    email_content{
                      html
                      raw {
                        type
                        text
                      }
                    }
                    signature {
                      html
                      raw {
                        type
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicCareerFooterBodyGspannPolicy {
            id
            slice_type
            items {
              policy_name {
                text
              }
              policy_link {
                url
              }
              policy_font_color
            }
          }
          ... on PrismicCareerFooterBodyFooterMenu {
            id
            slice_type
            items {
              footer_menu_text {
                text
              }
              footer_menu_link {
                url
              }
              footer_menu_font_color
            }
          }
        }
      }
    }

    serviceContentCardData: prismicHomepagePageContentCards {
      items {
        card_heading {
          text
        }
        card_subheading {
          text
        }
        card_description {
          text
        }
        card_button_text {
          text
        }
      }
    }
  }
`;
